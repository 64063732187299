@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/moulin/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/moulin/src/scss/utility/_mixin.scss";

a:link, a:visited, a:active {
  text-decoration: none;
}
/*-----------------------------------------------------------
VISUAL
------------------------------------------------------------*/
.mv{
	display: flex;
	justify-content: space-between;
	position: relative;
	height: calc(100vh - 80px);
	@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    background-attachment: inherit;
    height: calc(100vh - 120px);
  }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    background-attachment: inherit;
    height: calc(100vh - 120px);
  }
	@include max-screen(767px){
		height: auto;
	}
	&:before{
		content: "";
		width: 100%;
		height: 62.74%;
		max-height: 352px;
		position: absolute;
		top: 16%;
		left: 0;
		background: #EFEFEF;
		z-index: -1;
		@include screen(768px,1024px){
			height: 40%;
		}
		@include max-screen(767px){
			content: none;
		}
	}
	@include max-screen(767px){
		display: block;
	}
	&__left{
		width: calc(100% - 417px);
		background: url(/assets/img/top/visual1_right.jpg) top right/5px 100% repeat-x;
		position: relative;
		text-align: center;
		overflow: hidden;
		@include screen(768px,1000px){
			width: 60%;
		}
		@include max-screen(767px){
			width: 100%;
		}
		&--img{
			width: 100%;
			height: 100%;
			display: inline-block;
			vertical-align: top;
			position: relative;
			background: url(/assets/img/top/visual01.jpg) center top/auto 100% no-repeat;
			@include SP{
				display: none;
			}
		}
		&:before{
			content: "";
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			background: url(/assets/img/top/visual1_bg.jpg) left top/ 5px 100% repeat-x;
			@include SP{
				content: none;
			}
		}
		&--h1{
			@include font(40,64,0.06,500);
			@include noto-serif;
			position: absolute;
			bottom: 65px;
			left: 86px;
			z-index: 1;
			@include screen(768px,1024px){
				font-size: 30px;
				line-height: 1.6;
				left: 6.5%;
				bottom: 8%;
			}
			@include max-screen(767px){
				font-size: 22px;
				line-height: 34px;
				left: 26px;
				bottom: calc(50% - 15px);
			}
			@include max-screen(370px){
				font-size: 18px;
				line-height: 30px;
				left: 15px;
				bottom: calc(50% - 25px);
			}
		}
	}
	&__right{
		width: 417px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: right;
		@include min-screen(768px){
			max-height: 561px;
		}
		@include screen(768px,1000px){
			width: 40%;
			display: block;
		}
		@include max-screen(767px){
			display: block;
			width: 100%;
			position: relative;
			&:before{
				content: "";
				width: calc(100% - 20px);
				height: 79.4%;
				position: absolute;
				top: 0;
				right: 0;
				background: #EFEFEF;
				z-index: -1;
			}
		}
		.img{
			position: relative;
			overflow: hidden;
			text-align: center;
			&:before{
				content: "";
				width: 50%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 0;
			}
			@include max-screen(767px){
				background: none;
			}
		}
		&--top{
			display: block;
			width: 100%;
			height: 52.6%;
			padding: 11.2% 24.2% 4.9% 0;
			@include screen(768px,1000px){
				height: auto;
			}
			@include max-screen(767px){
				// padding: 0 66px 12px 86px;
				padding: 0 17.6% 3.2% 22.9%;
				height: auto;
			}
			.img{
				width: calc(100% + 27px);
				position: relative;
				left: -27px;
				z-index: 2;
				height: 100%;
				display: block;
				background: url(/assets/img/top/visual2_bg.jpg) top left/5px 100% repeat-x;
				&:before{
					background: url(/assets/img/top/visual2_bg2.jpg) top left/5px 100% repeat-x;
				}
				@include max-screen(767px){
					width: 100%;
					left: 0;
					margin-top: -25px;
				}
			}
		}
		&--bottom{
			width: 100%;
			height: 47.4%;
			padding: 0 32px 40px 45px;
			@include screen(768px,1000px){
				height: auto;
			}
			@include max-screen(767px){
				height: auto;
				// padding: 0 20px 0 133px;
				padding: 0 5.3% 0 35.4%;
			}
			.img{
				width: 100%;
				height: 100%;
				display: block;
				background: url(/assets/img/top/visual3_bg.jpg) top left/4px 100% repeat-x;
				&:before{
					background: url(/assets/img/top/visual3_bg2.jpg) top left/4px 100% repeat-x;
				}
			}
		}
		img{
			display: inline-block;
			max-height: 100%;
			position: absolute;
			height: 100%;
			width: auto;
			max-width: 300%;
			top: 0;
			right: 50%;
			@include transform_c(translateX(50%));
			@include min-screen(1400px){
				position: static;
				@include transform_c(translateX(0%));
			}
			@include screen(768px,1000px){
				position: static;
				width: 100%;
				height: auto;
				@include transform_c(translateX(0%));
			}
			@include max-screen(767px){
				width: 100%;
				position: static;
				height: auto;
				max-width: 100%;
				@include transform_c(translateX(0%));
			}
		}
	}
	&.tablet{
		display: block;
		height: auto;
		&:before{
			content: none;
		}
		.visual__left,.visual__right{
			width: 100%;
			height: auto;
			max-height: 200%;
		}
		.visual__left{
			background: none;
			&:before{
				content: none;
			}
			img.sp{
				display: block !important;
				width: 100%;
			}
			.img,&--img{
				display: none;
			}
			&--h1{
				font-size: 5.8vw;
				line-height: 1.5;
				left: 26px;
				bottom: calc(50% - 4vw);
			}
		}
		.visual__right{
			display: block;
			width: 100%;
			position: relative;
			&:before{
				content: "";
				width: calc(100% - 20px);
				height: 79.4%;
				position: absolute;
				top: 0;
				right: 0;
				background: #EFEFEF;
				z-index: -1;
			}
			.img{
				background: none;
			}
			&--top{
				padding: 0 17.6% 3.2% 22.9%;
				height: auto;
				.img{
					width: 100%;
					left: 0;
					margin-top: -6.6%;
				}
			}
			&--bottom{
				height: auto;
				padding: 0 5.3% 0 35.4%;
			}
		}
		img{
			width: 100%;
			position: static;
			height: auto;
			max-width: 100%;
			@include transform_c(translateX(0%));
		}
	}
}
//==========MAC : 1440:837================;
.visual__left--img{
	@include screen(1440px,1500px){
		background-position: top left -100px;
	}
}
/*-----------------------------------------------------------
SEC CHECK
------------------------------------------------------------*/
.sec-check{
	padding: 90px 20px 83px;
	@include max-screen(767px){
		padding: 68px 20px 45px;
	}
	&__top{
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include max-screen(767px){
			display: block;
		}
		.txt,.img{
			width: 50%;
			@include max-screen(767px){
				width: 100%;
			}
		}
		.txt{
			padding-bottom: 70px;
			text-align: left !important;
		}
		img{
			width: 100%;
			@include max-screen(767px){
				margin: 28px 0 -24px;
			}
		}
	}
	.ttl2{
		text-align: center;
		@include noto-serif;
		@include font(28,46,0.06,500);
		padding-top: 110px;
		padding-right: 6px;
		@include max-screen(767px){
			font-size: 18px;
			line-height: 30px;
			padding: 12px 3px 26px 0;
		}
	}
	.txt{
		@include font(16,42,0.08);
		padding-top: 27px;
		text-align: center;
		@include screen(768px,1000px){
			letter-spacing: 0.04em;
			font-size: 16px;
			line-height: 36px;
		}
		@include max-screen(767px){
			line-height: 36px;
			padding-top: 0;
			padding-bottom: 0;
			text-align: left;
		}
	}
	.ttl{
		@include font(28,46,0.06,500);
		@include noto-serif;
		@include screen(1001px,1024px){
			font-size: 24px;
			line-height: 42px;
		}
		@include screen(768px,1000px){
			font-size: 20px;
			line-height: 36px;
		}
		@include max-screen(767px){
			font-size: 18px;
			line-height: 30px;
		}
		@include max-screen(370px){
			br{
				display: none;
			}
		}
	}
	.c-link{
		margin-top: 75px;
		@include screen(768px,1024px){
			margin-top: 50px;
		}
		@include max-screen(767px){
			margin-top: 30px;
		}
	}
}
/*-----------------------------------------------------------
SECPRO
------------------------------------------------------------*/
.sec-pro{
	padding-bottom: 72px;
	@include max-screen(767px){
		padding-bottom: 75px;
	}
	.btn-search{
		padding: 142px 0 6px;
		@include max-screen(767px){
			padding: 47px 0 0;
		}
	}
}
/*-----------------------------------------------------------
SEC MAP
------------------------------------------------------------*/
.sec-map{
	background: #DADFED;
	padding: 97px 0 103px;
	@include max-screen(767px){
		padding: 31px 0 43px;
	}
	.wcm{
		max-width: 830px;
		text-align: center;
	}
	&__ttl{
		@include font(28,40,0.06,500);
		@include noto-serif;
		@include max-screen(767px){
			font-size: 18px;
			line-height: 26px;
		}
	}
	&__txt{
		@include font(16,28,0.06,500);
		padding: 25px 0 40px;
		@include max-screen(767px){
			font-size: 14px;
			text-align: left;
			padding: 14px 0 27px;
		}
	}
	&__lst{
		padding: 0 35px;
		display: flex;
		justify-content: space-around;
		@include max-screen(767px){
			display: block;
			padding: 0 21px;
		}
		@include max-screen(370px){
			padding: 0 10px;
		}
		.item{
			width: calc(50% - 70px);
			@include max-screen(767px){
				width: 100%;
				&:first-child{
					margin-bottom: 18px;
				}
			}
			&__ttl{
				position: relative;
				margin-bottom: 2px;
				@include max-screen(767px){
					text-align: left;
					img{
						display: none;
					}
				}
				&--p{
					position: absolute;
					top: calc(50% - 19px);
					width: 100%;
					@include font(18,22,0.04,bold);
					@include max-screen(767px){
						font-size: 16px;
						position: static;
					}
				}
			}
			&__select{
				width: 100%;
				border-radius: 0;
				border: none;
				box-shadow: none;
				height: 44px;
				@include font(22,44,0.06,500);
				padding: 0 15px;
				@include add_prefix(appearance,none);
				@include add_prefix(box-shadow,none);
				background: #fff url(/assets/img/common/ico_select.svg) right 12px center/9px 8px no-repeat;
				@include max-screen(767px){
					margin-top: 27px;
				}
			}
		}
	}
	.c-btn{
		max-width: 189px;
		width: 100%;
		padding: 14px 0;
		font-size: 16px;
		margin-top: 47px;
		@include max-screen(767px){
			margin-top: 44px;
			max-width: 250px;
		}
		&:before{
			right: 17px;
			width: 7px;
			height: 7px;
		}
	}
}
/*-----------------------------------------------------------
LOADING
------------------------------------------------------------*/
.keyvisual_main_imageWrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	-webkit-mask-image: radial-gradient(circle farthest-corner at 100% 0%, #000 60%, transparent 90%);
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: 200% 100%;
	-webkit-mask-position: 50% 0;
	transition-property: -webkit-mask-position;
	transition-property: mask-position;
	transition-property: mask-position, -webkit-mask-position;
	transition-duration: 1s;
	transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1)
}

.keyvisual_main_imageWrap.hide {
	-webkit-mask-position: -150% 0
}

.keyvisual_main_imageWrap-1 {
	z-index: 3
}

.keyvisual_main_imageWrap-2 {
	z-index: 1;
	visibility: hidden
}

.keyvisual_main_imageWrap-3 {
	z-index: 1;
	visibility: hidden
}